import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Tab, Row, Col, Nav } from 'react-bootstrap';

import { BASE_URL } from 'utils/api';
import { useFormatMessage } from 'hooks/useFormatMessage';
import ModuleSetupView from './ModuleSetup/ModuleSetupView';
import ContentSetupView from './ContentSetup/ContentSetupView';
import SharedContentSetupView from './SharedContentSetup/SharedContentSetupView';
import ModuleContentSetupProvider from './ModuleContentProvider';
import RoleCheck from '../RoleCheck';
import './module-content-setup-view.scss';

const ModuleContentSetupView = ({ selectedOrg, authToken }) => {
  const t = useFormatMessage();

  return (
    <ModuleContentSetupProvider>
      <Container>
        <Row className="module-content-setup__tabs justify-content-center">
          <Col xs={12}>
            <Tab.Container key={selectedOrg} mountOnEnter={true} defaultActiveKey={'modules'} id="modules-contents">
              <div className="nav-wrapper">
                <Nav variant="pills" className="flex-row">
                  <RoleCheck
                    allowed={['ADMIN', 'MANAGER', 'PARTNER', 'CREATOR']}
                    component={
                      <>
                        <Nav.Item>
                          <Nav.Link eventKey="modules">{t('module-setup/modules')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="contents">{t('content-setup/contents')}</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="shared-contents">{t('share/shared-contents')}</Nav.Link>
                        </Nav.Item>
                      </>
                    }
                  />
                </Nav>
                <a className="download-advanced-statistics-btn" target="_blank" rel="noopener noreferrer" href={`${BASE_URL}/organizations/${selectedOrg}/stats/download?token=${authToken}`}>
                  {t('statstics/download-advanced-statistics')}
                </a>
              </div>

              <Tab.Content>
                <Tab.Pane eventKey="modules">
                  <ModuleSetupView selectedOrg={selectedOrg} />
                </Tab.Pane>

                <Tab.Pane eventKey="contents">
                  <ContentSetupView selectedOrg={selectedOrg} />
                </Tab.Pane>

                <Tab.Pane eventKey="shared-contents">
                  <SharedContentSetupView selectedOrg={selectedOrg} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
    </ModuleContentSetupProvider>
  );
};

ModuleContentSetupView.propTypes = {
  selectedOrg: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired
};

ModuleContentSetupView.defaultProps = {};

const mapStateToProps = ({ userState }) => {
  const authToken = get(userState, 'user.authToken', null);

  return {
    authToken
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModuleContentSetupView));
