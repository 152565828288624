// /*eslint-disable*/
import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { get, pick } from 'lodash';
import Slider from 'react-rangeslider';
import 'react-rangeslider/lib/index.css';

import { ErrorsPropTypesShape, SuccessMessagePropTypesShape, ComputedMatchPropTypesShape, HistoryPropTypesShape, CurrentContentPropTypesShape } from 'utils/core-proptypes';
import { mediaSelector } from 'utils/selectors';
import { Radio, RadioGroup } from 'atoms/Radio';
import ImageThumbnail from 'atoms/ImageThumbnail';
import Switch from 'atoms/Switch';

import Feedback from 'atoms/Feedback';
import { DEFAULT_CONTENT_PREFERENCES } from 'utils/constants';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { AllowedMediaTypeSource, getMediaPayload } from 'utils/calculationUtilities';
import { mixedContentAdditionalPrefs, mixedPreferenceConstantToKeyMap } from 'utils/types';
import ActionRowContentPreference from '../../ActionRowContentPreference';
import CommonContentPreferences from '../../CommonContentPreferences';
import { addPreference, updatePreference } from '../../actions';
import './mixed-preference.scss';

const initialState = {
  preferences: {
    ...DEFAULT_CONTENT_PREFERENCES.MIXED
  },
  contentPreferencePristine: true
};

const reducer = (state, action) => {
  const { key, value } = action;
  switch (action.type) {
    case 'inputChange':
    case 'dropDown':
    case 'inputRadio':
    case 'sliderChange':
      return {
        ...state,
        preferences: { ...state.preferences, [key]: value },
        contentPreferencePristine: false
      };

    case 'setThumbnail': {
      const { thumbnail } = action;
      return {
        ...state,
        preferences: { ...state.preferences, thumbnail },
        contentPreferencePristine: false,
        thumbnailPristine: true
      };
    }
    case 'initializeUpdateState': {
      const { currentContent } = action;
      return {
        ...state,
        ...currentContent,
        preferences: {
          ...(action.currentContent.preferences || initialState.preferences)
        },
        contentPreferencePristine: true,
        thumbnailPristine: false
      };
    }
    default:
      throw new Error();
  }
};

const McqPreference = ({ currentContent, computedMatch, ...props }) => {
  const t = useFormatMessage();
  const [state, dispatcher] = useReducer(reducer, initialState);
  const { preferences = {} } = state;
  const contentId = get(currentContent, '_id', null);
  const thumbnailValue = preferences.thumbnail && mediaSelector({ media: preferences.thumbnail });
  const { orgid } = computedMatch.params;

  useEffect(() => {
    if (currentContent) {
      dispatcher({
        type: 'initializeUpdateState',
        currentContent: currentContent
      });
    }
  }, [currentContent]);

  const handleFileModalSave = thumbnail => {
    dispatcher({
      type: 'setThumbnail',
      thumbnail
    });
  };

  const handleRevertToSaved = () => {
    dispatcher({
      type: 'initializeUpdateState',
      currentContent: currentContent
    });
  };

  const onSave = () => {
    const validKeys = [
      'contentName',
      'contentType',
      'difficultyLevel',
      'estimatedTime',
      'description',
      'passPercent',
      'displayQuestionMode',
      'shuffleAnswerChoices',
      'showExplanation',
      'noOfTestAttempts',
      'showRightAnswer',
      'thumbnailType',
      'thumbnailUrl',
      'showContentOnDiploma',
      'askForConfirmation',
      'mandatoryContent'
    ];

    const payload = pick(
      {
        ...state,
        ...state.preferences,
        contentType: 'MIXED',
        ...getMediaPayload(state.preferences.thumbnail, state.thumbnailPristine, 'thumbnail')
      },
      validKeys
    );

    if (currentContent && contentId) {
      props.updatePreference({ payload, id: contentId, organizationId: orgid });
    } else {
      props.addPreference({ payload, organizationId: orgid });
    }
  };

  return (
    <div className="content-preference">
      <CommonContentPreferences
        contentType="mixed"
        description={preferences.description}
        estimatedTime={preferences.estimatedTime}
        contentName={preferences.contentName}
        dispatcher={dispatcher}
        difficultyLevel={preferences.difficultyLevel}
      />
      <Row className="content-preference__body">
        <Col>
          <Row>
            <Col className="content-preference__row-left-border" style={{ borderTop: 'solid 1px #d2d3d2', paddingBottom: 0 }}>
              {t('common/thumbnail-text')}
              <p className="content-preference__help-text">{t('common-content-preference/help-text/thumbnail')}</p>
              <Col className="content-preference__body__thumbnail-container" style={{ marginTop: '20px' }}>
                <ImageThumbnail
                  variant="horizontal"
                  shouldUploadMindzeedPlaceholderImage={!computedMatch?.params?.id && !contentId}
                  defaultThumbnail={thumbnailValue}
                  allowedExtension="image/*,image/gif"
                  allowedFileType={[AllowedMediaTypeSource.FILE]}
                  handleSave={handleFileModalSave}
                />
              </Col>
            </Col>
          </Row>

          <Row>
            <Col className="content-preference__row-left-border" style={{ paddingBottom: 0 }}>
              <div className="content-preference__pass-slider__title">{t('common-content-preference/passing-score')}</div>
              <p className="content-preference__help-text">{t('common-content-preference/help-text/pass-slider')}</p>
              <div className="content-preference__pass-slider">
                <Row>
                  <Col>
                    <Slider
                      className="content-preference__pass-slider__selector"
                      min={0}
                      max={100}
                      value={preferences.passPercent}
                      onChange={value => {
                        dispatcher({
                          type: 'sliderChange',
                          key: 'passPercent',
                          value
                        });
                      }}
                    />
                  </Col>
                  <Col>
                    <div className="content-preference__pass-slider__progress">{preferences.passPercent}%</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="content-preference__row-left-border">
              <RadioGroup
                helpTextClassName="content-preference__help-text"
                helpText={t('common-content-preference/help-text/feedback')}
                label={t('common-content-preference/feedback')}
                name="common-content-feedback"
                onSelect={({ value }) =>
                  dispatcher({
                    type: 'inputRadio',
                    key: 'showExplanation',
                    value
                  })
                }
              >
                <Radio value="SHOW_EXP" checked={preferences.showExplanation === 'SHOW_EXP'} label={t('common-content-preference/show-feedback')} />
                <Radio value="SHOW_EXP_WRONG" checked={preferences.showExplanation === 'SHOW_EXP_WRONG'} label={t('common-content-preference/show-feedback-if-answer-is-wrong')} />
                <Radio value="SHOW_EXP_RIGHT" checked={preferences.showExplanation === 'SHOW_EXP_RIGHT'} label={t('common-content-preference/show-feedback-if-answer-is-correct')} />
                <Radio value="DO_NOT_SHOW" checked={preferences.showExplanation === 'DO_NOT_SHOW'} label={t('common-content-preference/do-not-show-feedback')} />
              </RadioGroup>
            </Col>
          </Row>

          <Row>
            <Col className="content-preference__row-left-border">
              <RadioGroup
                helpTextClassName="content-preference__help-text"
                helpText={t('common-content-preference/help-text/attempts')}
                label={t('common-content-preference/retries')}
                name="common-content-attempts"
                onSelect={({ value }) =>
                  dispatcher({
                    type: 'inputRadio',
                    key: 'noOfTestAttempts',
                    value
                  })
                }
              >
                <Radio value="ONE" checked={preferences.noOfTestAttempts === 'ONE'} label={t('common/one')} />
                <Radio value="ONE_IN_24" checked={preferences.noOfTestAttempts === 'ONE_IN_24'} label={t('common/one-every-24-hours')} />
                <Radio value="UNLIMITED" checked={preferences.noOfTestAttempts === 'UNLIMITED'} label={t('common/unlimited')} />
              </RadioGroup>
            </Col>
          </Row>

          <ActionRowContentPreference
            handleRevertToSaved={handleRevertToSaved}
            onSave={onSave}
            contentPreferencePristine={state.contentPreferencePristine}
            organizationId={orgid}
            currentContent={currentContent}
          />
        </Col>
        <Col className="content-preference__body__thumbnail-container">
          {Object.keys(mixedContentAdditionalPrefs).map(pref => (
            <Row key={pref} style={{ borderTop: pref === 'REVEAL_ANSWERS' ? 'solid 1px #d2d3d2' : 'none' }} className="content-preference__row">
              <Col className="content-preference__row__switch-label">{t(mixedContentAdditionalPrefs[pref])}</Col>
              <Col className="content-preference__row__switch">
                <Switch
                  id={pref}
                  onSelect={() => {
                    let value;
                    if (pref === 'SHOW_BLOCK_ONE_BY_ONE') {
                      value = preferences[mixedPreferenceConstantToKeyMap[pref]] === 'ONE_BY_ONE' ? 'ALL' : 'ONE_BY_ONE';
                    } else {
                      value = preferences[mixedPreferenceConstantToKeyMap[pref]] === 'false' ? 'true' : 'false';
                    }
                    dispatcher({
                      type: 'inputRadio',
                      key: mixedPreferenceConstantToKeyMap[pref],
                      value
                    });
                  }}
                  value={
                    pref === 'SHOW_BLOCK_ONE_BY_ONE'
                      ? preferences[mixedPreferenceConstantToKeyMap[pref]] === 'ONE_BY_ONE'
                        ? true
                        : false
                      : preferences[mixedPreferenceConstantToKeyMap[pref]] === 'false'
                      ? false
                      : true
                  }
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Feedback
        allowedTypesKey="MCQPreferences"
        message={props.message.ADD_PREFERENCE_SUCCESS || props.message.UPDATE_PREFERENCE_SUCCESS || []}
        errorList={props.errors.ADD_PREFERENCE_FAILURE || props.errors.UPDATE_PREFERENCE_FAILURE || []}
      />
    </div>
  );
};

McqPreference.propTypes = {
  addPreference: PropTypes.func.isRequired,
  updatePreference: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(ErrorsPropTypesShape).isRequired,
  message: PropTypes.arrayOf(SuccessMessagePropTypesShape).isRequired,
  currentContent: CurrentContentPropTypesShape,
  history: HistoryPropTypesShape,
  computedMatch: ComputedMatchPropTypesShape
};

const mapStateToProps = ({ contentState }) => {
  const errors = get(contentState, 'errors', []);
  const message = get(contentState, 'message', []);

  return {
    error: contentState.error,
    errors,
    message
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addPreference: bindActionCreators(addPreference, dispatch),
    updatePreference: bindActionCreators(updatePreference, dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(McqPreference));
