import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { get, pick } from 'lodash';
import moment from 'moment';

import Button from 'atoms/Button';
import Checkbox from 'atoms/Checkbox';
import DropDown from 'atoms/DropDown';
import IconWrapper from 'atoms/IconWrapper';
import GroupAtoms from 'atoms/GroupAtoms';
import Input from 'atoms/Input';
import Modal from 'atoms/Modal';
import ImageThumbnail from 'atoms/ImageThumbnail';
import { fetcher } from 'hooks/useFetcher';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { mediaSelector } from 'utils/selectors';
import { AllowedMediaTypeSource, getMediaPayload, getLowerCaseContentType } from 'utils/calculationUtilities';
import NoImage from 'assets/icons/noimage.svg';
import ModuleSpecificMCQPreference from '../../ModalElements/ModuleSpecificMCQPreference';
import ModuleSpecificPresentationPreference from '../../ModalElements/ModuleSpecificPresentationPreference';
import ModuleSpecificCarouselPreference from '../../ModalElements/ModuleSpecificCarouselPreference';
import ModuleSpecificMixedContentPreference from '../../ModalElements/ModuleSpecificMixedContentPreference';
import ModulePreferenceBelowTabs from './ModulePreferenceBelowTabs';
import DatePicker from 'atoms/DatePicker';
import { createModule, dispatchCustomAction } from './actions';
import { reducer } from './reducer';
import './module-preference.scss';
import RoleCheck from '../../RoleCheck';

const ModulePreference = ({ computedMatch, currentModule, currentOrganization, ...props }) => {
  const t = useFormatMessage();
  const { orgid: organizationId } = computedMatch.params;
  const [state, dispatcher] = useReducer(reducer, {
    assignedGroups: [],
    notAssignedGroups: [],
    currentModule: {
      moduleName: 'My New Module'
    },
    contents: []
  });

  useEffect(() => {
    dispatcher({
      type: 'initialState',
      payload: { currentModule }
    });
  }, [currentModule]);

  const {
    currentModule: { _id: moduleId, moduleName, thumbnail, startDate, endDate, status, isOpen },
    contents,
    assignedGroups,
    notAssignedGroups,
    modulePreferencePristine,
    openContentPreferenceModal,
    modalPreferenceTitle,
    modalPreferenceData,
    contentPreferenceModalPristine
  } = state;

  const handleSaveOrUpdateModule = async () => {
    const { currentModule } = state;
    const payload = {
      ...currentModule,
      ...getMediaPayload(currentModule.thumbnail, state.thumbnailPristine, 'thumbnail'),
      groups: assignedGroups.map(grp => grp._id),
      contents: contents.map(content => content.settings)
    };

    if (moduleId) {
      const validAttributes = ['moduleName', 'startDate', 'endDate', 'contents', 'groups', 'thumbnailType', 'thumbnailUrl'];
      try {
        const result = await fetcher(`/organizations/${organizationId}/modules/${moduleId}`, 'PUT', {}, JSON.stringify(pick(payload, validAttributes)));
        if (result.errors) {
          props.dispatchCustomAction('UPDATE_MODULE_FAILURE');
        } else {
          dispatcher({ type: 'saveSuccess' });
          props.dispatchCustomAction('UPDATE_MODULE_SUCCESS', result);
        }
      } catch (err) {
        props.dispatchCustomAction('UPDATE_MODULE_FAILURE');
      }
    } else props.createModule({ payload, orgid: organizationId });
  };

  const handleChangeModuleType = async () => {
    const body = { moduleType: !isOpen ? 'PUBLIC' : 'PRIVATE' };
    const result = await fetcher(`/organizations/${organizationId}/modules/${moduleId}/moduleType`, 'PUT', {}, JSON.stringify(body));
    if (result.module) {
      dispatcher({ type: 'checkboxChange', isOpen: !isOpen });
    }
  };

  const handleChangeThumnail = thumbnail => {
    dispatcher({
      type: 'setThumbnail',
      thumbnail
    });
  };

  const handleAddGroup = eventKey => {
    if (eventKey.id === 'add-all-group') {
      dispatcher({ type: 'addAllGroup', notAssignedGroups: notAssignedGroups && notAssignedGroups.filter(x => x.usersCount > 0) });
      return;
    }
    const group = notAssignedGroups.find(grp => grp._id === eventKey._id);
    dispatcher({ type: 'dropDownChange', group });
  };

  const handleRemoveGroup = id => {
    dispatcher({
      type: 'removeAssignedGroup',
      id
    });
  };

  const handleDateChange = (momentDate, type) => {
    dispatcher({
      type: 'inputChange',
      key: type,
      value: momentDate ? moment(momentDate).format('YYYY-MM-DD') : null
    });
  };

  const handleSaveContentSpecificPreference = async () => {
    const contentId = modalPreferenceData._id;
    const validAttributes = [
      'passPercent',
      'showContentInOrder',
      'showExplanation',
      'showRightAnswer',
      'noOfTestAttempts',
      'displayQuestionMode',
      'noOfTimeCanBeAccessed',
      'downloadable',
      'askForConfirmation',
      'showQuestionsAfterVideo',
      'noOfQuestionsToDisplay',
      'showContentOnDiploma',
      'shuffleAnswerChoices',
      'mandatoryContent'
    ];

    const payload = {
      preferences: pick(modalPreferenceData, validAttributes)
    };
    const result = await fetcher(`/organizations/${organizationId}/modules/${moduleId}/contents/${contentId}`, 'PUT', {}, JSON.stringify(payload));
    if (result.errors) {
      return props.dispatchCustomAction('CONTENT_SPECIFIC_PREFERENCE_FAILURE');
    }
    dispatcher({ type: 'updateContentPreference', payload: get(result, 'module.updatedContentSettings', {}) });
    return props.dispatchCustomAction('CONTENT_SPECIFIC_PREFERENCE_SUCCESS');
  };

  const renderModuleSpecifictPreference = contentType => {
    switch (contentType) {
      case 'mcq': {
        return <ModuleSpecificMCQPreference modalPreferenceData={modalPreferenceData} dispatcher={dispatcher} />;
      }
      case 'presentation': {
        return <ModuleSpecificPresentationPreference modalPreferenceData={modalPreferenceData} dispatcher={dispatcher} />;
      }
      case 'story':
      case 'carousel': {
        return <ModuleSpecificCarouselPreference modalPreferenceData={modalPreferenceData} dispatcher={dispatcher} />;
      }
      case 'mixed': {
        return <ModuleSpecificMixedContentPreference modalPreferenceData={modalPreferenceData} dispatcher={dispatcher} />;
      }
      default:
        return null;
    }
  };

  const isStatusActive = !(status === 'INACTIVE' || !status);

  return (
    <React.Fragment>
      <Container className="module-preference" key={moduleId}>
        <Row className="module-preference__row module-preference__row--border-bottom">
          <Col className="module-preference__row__space-between">
            <Row style={{ margin: 0, justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
              <div>
                <div className="d-flex flex-row align-items-center">
                  <IconWrapper type={isStatusActive ? 'GreenDot' : 'RedDot'} className="mr-1 mb-1" />
                  <span style={{ width: 250 }}>{isStatusActive ? t('module-preference/the-module-is-active-and-in-use') : t('module-preference/the-module-not-activated')}</span>
                  <RoleCheck
                    allowed={['ADMIN', 'PARTNER', 'MANAGER']}
                    component={
                      <>
                        <DatePicker
                          id={`${moduleId}-start-date`}
                          onChange={momentDate => handleDateChange(momentDate, 'startDate')}
                          date={startDate}
                          displayFormat={get(currentOrganization, 'settings.dateFormat', 'DD/MM/YYYY')}
                          placeholder={get(currentOrganization, 'settings.dateFormat', 'DD/MM/YYYY')}
                          showClearDate
                          iconType={startDate ? 'SortDateOn' : 'SortDateOff'}
                          iconStyles={{ width: 19, height: 19 }}
                          label={t('module-preference/start-date')}
                          labelAlign="horizontal"
                          isOutsideRange={day => {
                            if (!endDate) {
                              return false;
                            }
                            return day.isAfter(endDate, 'day');
                          }}
                        />
                        <div className="ml-4">
                          <DatePicker
                            id={`${moduleId}-end-date`}
                            onChange={momentDate => handleDateChange(momentDate, 'endDate')}
                            date={endDate}
                            displayFormat={get(currentOrganization, 'settings.dateFormat', 'DD/MM/YYYY')}
                            placeholder={get(currentOrganization, 'settings.dateFormat', 'DD/MM/YYYY')}
                            showClearDate
                            iconType={endDate ? 'SortDateOn' : 'SortDateOff'}
                            iconStyles={{ width: 19, height: 19 }}
                            label={t('module-preference/end-date')}
                            labelAlign="horizontal"
                            isOutsideRange={day => {
                              if (!startDate) {
                                return false;
                              }
                              return day.isBefore(startDate, 'day');
                            }}
                          />
                        </div>
                      </>
                    }
                  />
                </div>
                <RoleCheck
                  allowed={['ADMIN', 'PARTNER', 'MANAGER']}
                  component={<div style={{ fontSize: 12, marginTop: 10, color: '#a5a8a5' }}>{t('module-preference/the-module-activate-hint')}</div>}
                />
              </div>
              <div>
                <Button onClick={handleSaveOrUpdateModule} className="ml-2" notPristine={modulePreferencePristine}>
                  {t('common/save')}
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
        <Row className="module-preference__row">
          <Col className="module-preference__row">
            <div className="module-preference__row__title">{t('module-preference/module-name')}</div>
            <Input
              className="module-preference__row__input-title"
              placeholder={t('module-preference/module-name')}
              value={moduleName}
              onChange={e => {
                dispatcher({
                  type: 'inputChange',
                  key: 'moduleName',
                  value: e.target.value
                });
              }}
            />
            <div className="module-preference__group">
              <div className="module-preference__row__title">
                <IconWrapper type="User" style={{ height: 12 }} />
                {t('module-preference/user-groups')}
              </div>

              <div className="module-preference__group__assign">
                <DropDown
                  onSelect={handleAddGroup}
                  disabled={!contents.length > 0 || !notAssignedGroups.filter(grp => grp.usersCount > 0).length || !(currentModule.data && currentModule.data.length > 0)}
                  isColored
                  isStaticHeading
                  placeholder={t('common/add-group')}
                  values={[{ id: 'add-all-group', value: t('module-preference/add-all-group') }, ...notAssignedGroups.filter(grp => grp.usersCount > 0).sort((a, b) => a.value.localeCompare(b.value))]}
                  className="user-management__form__dropdown"
                />
                {assignedGroups.map(group => {
                  return <GroupAtoms key={group._id} group={group} onClose={handleRemoveGroup} />;
                })}
              </div>
            </div>
          </Col>
          <Col className="module-preference__thumbnail">
            <div>
              <div className="module-preference__row__title">{t('common/thumbnail-text')}</div>
              <ImageThumbnail
                placeholderIcon={NoImage}
                shouldUploadMindzeedPlaceholderImage={!computedMatch?.params?.id && !moduleId}
                allowedExtension="image/*,image/gif"
                defaultThumbnail={thumbnail ? mediaSelector({ media: thumbnail }) : null}
                allowedFileType={[AllowedMediaTypeSource.FILE]}
                handleSave={handleChangeThumnail}
              />
            </div>
          </Col>
        </Row>
        <Row className="module-preference__row module-preference__row--border-top">
          <Col className="module-preference__row__space-between">
            <p>
              <Checkbox checked={isOpen} onChange={() => handleChangeModuleType()} />
              <span>{t('module-preference/open-module')}</span>
            </p>

            {isOpen ? (
              <a target="_blank" rel="noopener noreferrer" href={`${window.location.origin}/content-list/${moduleId}`}>
                {window.location.origin}/content-list/{moduleId}
              </a>
            ) : (
              t('module-preference/link-is-generated-that-provides-access-to-the-module-without-logging-in')
            )}
          </Col>
        </Row>
      </Container>
      <ModulePreferenceBelowTabs dispatcher={dispatcher} orgId={organizationId} currentOrganization={currentOrganization} moduleId={moduleId} contents={contents} />
      <Container style={{ padding: 0 }}>
        <Modal
          heading={`${t('common/preferences')}: ${modalPreferenceTitle}`}
          modalBody={modalPreferenceData && renderModuleSpecifictPreference(getLowerCaseContentType(modalPreferenceData.contentType))}
          notPristine={contentPreferenceModalPristine}
          showFooter={false}
          showSaveFooter={true}
          show={openContentPreferenceModal}
          className={'module-preference__modal'}
          onSave={handleSaveContentSpecificPreference}
          onHide={() => dispatcher({ type: 'closeContentPreferenceModal' })}
        />
      </Container>
      {/* <Row>
        <Feedback
          style={{ margin: 0, justifyContent: 'space-between', width: '100%', alignItems: 'center' }}
          allowedTypesKey="ModulePreferences"
          message={props.message.CREATE_MODULE_SUCCESS || props.message.UPDATE_MODULE_SUCCESS}
          errorList={props.errors.CREATE_MODULE_FAILURE || props.errors.UPDATE_MODULE_FAILURE}
        />
      </Row> */}
    </React.Fragment>
  );
};

ModulePreference.propTypes = {
  computedMatch: PropTypes.shape({
    params: PropTypes.shape({
      orgid: PropTypes.string,
      id: PropTypes.string
    })
  }),
  currentModule: PropTypes.shape({
    _id: PropTypes.string,
    thumbnail: PropTypes.shape({}),
    data: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  currentOrganization: PropTypes.shape({}),
  createModule: PropTypes.func.isRequired,
  dispatchCustomAction: PropTypes.func.isRequired,
  groupsList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  message: PropTypes.shape({
    CREATE_MODULE_SUCCESS: PropTypes.bool,
    UPDATE_MODULE_SUCCESS: PropTypes.bool
  }).isRequired,
  errors: PropTypes.shape({
    CREATE_MODULE_FAILURE: PropTypes.string,
    UPDATE_MODULE_FAILURE: PropTypes.bool
  }).isRequired
};

ModulePreference.defaultProps = {
  currentModule: null
};

const mapStateToProps = ({ modulesState, groupState }) => {
  const errors = get(modulesState, 'errors', {});
  const currentModule = get(modulesState, 'currentModule', {});

  const message = get(modulesState, 'message', []);
  return {
    errors,
    message,
    groupsList: groupState.groupsList,
    currentModule
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createModule: bindActionCreators(createModule, dispatch),
    dispatchCustomAction: bindActionCreators(dispatchCustomAction, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModulePreference));
