import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import uuidv4 from 'uuid/v4';

import Input from 'atoms/Input';
import Button from 'atoms/Button';
import Switch from 'atoms/Switch';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { updateAccountCustomFieldsConfig } from '../actions';
import './custom-fields.scss';

const reducer = (state, action) => {
  switch (action.type) {
    case 'initializeState': {
      const { customFields = [] } = action.payload;
      return {
        ...state,
        customFields,
        touched: false
      };
    }
    case 'addCustomField': {
      return {
        ...state,
        customFields: [...state.customFields, { id: uuidv4(), key: '', name: '', showOnModuleStats: false }],
        touched: true
      };
    }
    case 'removeCustomField': {
      const { index } = action;
      return {
        ...state,
        customFields: state.customFields.filter((customField, i) => i !== index),
        touched: true
      };
    }
    case 'onChange': {
      const { index, key, value } = action;
      const customFields = state.customFields.map((customField, i) => (i === index ? { ...customField, [key]: value } : customField));
      return {
        ...state,
        customFields,
        touched: true
      };
    }
    case 'setStateAsUnTouched':
      return {
        ...state,
        touched: false
      };
    default:
      throw new Error();
  }
};

const CustomFields = ({ selectedOrg, selectedOrgDetails, hasSelectedOrgUpdated, updateAccountCustomFieldsConfig }) => {
  const t = useFormatMessage();
  const [state, dispatcher] = useReducer(reducer, { customFields: [], touched: false });

  useEffect(() => {
    if (hasSelectedOrgUpdated) {
      dispatcher({ type: 'setStateAsUnTouched' });
    }
  }, [hasSelectedOrgUpdated]);

  useEffect(() => {
    if (!isEmpty(selectedOrgDetails)) {
      const { customFields = [] } = selectedOrgDetails;
      dispatcher({
        type: 'initializeState',
        payload: { customFields }
      });
    }
  }, [selectedOrgDetails]);

  const handleUpdateSsoConfig = () => {
    updateAccountCustomFieldsConfig({
      organizationId: selectedOrg,
      payload: { customFields: state.customFields }
    });
  };

  return (
    <React.Fragment>
      <Container className="custom-fields_container p-0">
        {state.customFields.map((customField, index) => (
          <>
            <Row noGutters={false} key={customField.id} className="custom-fields_container__row">
              <Col xs={5}>
                <span>Key</span>
                <Input
                  placeholder="Key"
                  onChange={e =>
                    dispatcher({
                      index,
                      type: 'onChange',
                      key: 'key',
                      value: e.target.value
                    })
                  }
                  value={customField.key}
                />
              </Col>
              <Col xs={5}>
                <span>Name</span>
                <Input
                  placeholder="Name"
                  onChange={e =>
                    dispatcher({
                      index,
                      type: 'onChange',
                      key: 'name',
                      value: e.target.value
                    })
                  }
                  value={customField.name}
                />
              </Col>

              <Col xs={2}>
                <Button
                  block
                  variant="secondary"
                  onClick={() => {
                    dispatcher({
                      type: 'removeCustomField',
                      index
                    });
                  }}
                  style={{ borderStyle: 'dashed', borderColor: 'indianred', color: 'indianred', marginTop: 16 }}
                >
                  Delete
                </Button>
              </Col>

              <Col xs={12}>
                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -16, marginTop: 16, marginBottom: 16 }}>
                  <Switch
                    id="showOnModuleStats"
                    onSelect={() => {
                      dispatcher({
                        index,
                        type: 'onChange',
                        key: 'showOnModuleStats',
                        value: !customField.showOnModuleStats
                      });
                    }}
                    value={customField.showOnModuleStats}
                  />
                  <div style={{ marginLeft: '16px' }}>Show On Module Statistics</div>
                </div>
              </Col>
            </Row>
          </>
        ))}

        <Row>
          <Col>
            <Button
              block
              variant="secondary"
              onClick={() => {
                dispatcher({
                  type: 'addCustomField'
                });
              }}
              style={{ minWidth: '100px', borderStyle: 'dashed', marginTop: 16 }}
            >
              Add New Custom Field
            </Button>
          </Col>
        </Row>

        <Row className="custom-fields_container__row__action">
          <Col>
            <Button notPristine={!state.touched} onClick={handleUpdateSsoConfig} style={{ minWidth: '100px' }}>
              {t('common/save')}
            </Button>
            <div className="custom-fields_container__row__action--touched">{state.touched ? t('common/you-have-unsaved-changes') : ''}</div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

CustomFields.propTypes = {
  selectedOrg: PropTypes.string.isRequired,
  updateAccountCustomFieldsConfig: PropTypes.func.isRequired,
  hasSelectedOrgUpdated: PropTypes.bool.isRequired,
  selectedOrgDetails: PropTypes.shape({
    customFields: PropTypes.array
  })
};

CustomFields.defaultProps = {
  hasSelectedOrgUpdated: false
};

const mapStateToProps = ({ accountSettings }) => {
  const { selectedOrgDetails, hasSelectedOrgUpdated } = accountSettings;
  return {
    selectedOrgDetails,
    hasSelectedOrgUpdated
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAccountCustomFieldsConfig: bindActionCreators(updateAccountCustomFieldsConfig, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomFields));
