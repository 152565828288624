import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

import { MCQPropTypesShape } from 'utils/core-proptypes';
import Question from './Question';
import './mixed-mrq-attempt.scss';

const MRQItemView = ({
  _id,
  showAllMode,
  componentData: mcq,
  totalItems,
  preferences,
  moduleId,
  contentId,
  attemptId,
  index,
  isPreview,
  showMixedItemIndex,
  isAuthenticated,
  onSubmit,
  mixedComponentSubmit
}) => {
  const [showResponse, setShowResponse] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(true);
  const [showError, setShowError] = useState(false);
  const [explanation, setExplanation] = useState('');
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [correctKeys, setCorrectKeys] = useState([]);
  const [isOptionTouched, setIsOptionTouched] = useState(false);
  const [isOptionsDisabled, setIsOptionsDisabled] = useState(false);
  const { showExplanation, showRightAnswer, displayQuestionMode } = preferences;
  useEffect(() => {
    setIsOptionsDisabled(false);
    setShowResponse(false);
    setExplanation('');
    setIsOptionTouched(false);
    setCorrectKeys([]);
    setSelectedKeys([]);
  }, [attemptId]);

  const handleShowDescription = (description, success) => {
    switch (showExplanation) {
      case 'SHOW_EXP': {
        setExplanation(description);
        break;
      }
      case 'SHOW_EXP_RIGHT': {
        if (success) {
          setExplanation(description);
        }
        break;
      }
      case 'SHOW_EXP_WRONG': {
        if (!success) {
          setExplanation(description);
        }
        break;
      }

      case 'DO_NOT_SHOW': {
        break;
      }
      default:
        break;
    }
  };

  const handleChangeOption = e => {
    const { value } = e.target;
    if (selectedKeys.includes(value)) {
      setSelectedKeys(selectedKeys.filter(x => x !== value));
    } else {
      setSelectedKeys([...selectedKeys, value]);
    }
  };

  const handleSubmit = () => {
    setShowSubmitButton(false);
    if (isPreview) {
      const { correct, explanation: description } = mcq;
      const success = correct.sort().join(',') === selectedKeys.sort().join(',');
      setIsOptionTouched(true);
      if (success) {
        setShowResponse(true);
      } else {
        setShowResponse(false);
      }
      if (showRightAnswer === 'true') {
        setCorrectKeys(correct);
      }
      handleShowDescription(description, success);
      setIsOptionsDisabled(true);
      onSubmit({ key: _id, success, index });
    } else {
      const body = { answer: selectedKeys, attemptId };
      mixedComponentSubmit({
        body,
        moduleId,
        contentId,
        mixedComponentId: _id,
        attemptId,
        isPublic: !isAuthenticated
      }).then(res => {
        if (res.error) {
          setShowError(true);
          return;
        }
        const { success, explanation: description, correct = [] } = res.payload;
        setIsOptionTouched(true);
        if (success) {
          setCorrectKeys(correct);
          setShowResponse(true);
        } else {
          setCorrectKeys(correct);
          setShowResponse(false);
        }
        setExplanation(description);
        setIsOptionsDisabled(true);
        onSubmit({ key: _id, success, index });
      });
    }
  };

  const handleRetry = () => {
    setShowSubmitButton(false);
    const body = { answer: selectedKeys, attemptId };
    setShowError(false);

    mixedComponentSubmit({
      body,
      moduleId,
      contentId,
      mixedComponentId: _id,
      attemptId,
      isPublic: !isAuthenticated
    }).then(res => {
      if (res.error) {
        setShowError(true);
        return;
      }
      const { success, explanation: description, correct = [] } = res.payload;
      setIsOptionTouched(true);
      if (success) {
        setCorrectKeys(correct);
        setShowResponse(true);
      } else {
        setCorrectKeys(correct);
        setShowResponse(false);
      }
      setExplanation(description);
      setIsOptionsDisabled(true);
      onSubmit({ key: _id, success, index });
    });
  };

  if (
    (displayQuestionMode === 'ONE_BY_ONE' && showMixedItemIndex === index + 1) ||
    (displayQuestionMode === 'ONE_BY_ONE' && showAllMode && index + 1 !== totalItems) ||
    (displayQuestionMode === 'ALL' && index !== totalItems)
  ) {
    return (
      <Container>
        <Question
          index={index}
          showQuestionNumber={displayQuestionMode === 'ONE_BY_ONE' && !showAllMode}
          totalItems={totalItems}
          mcq={mcq}
          explanation={explanation}
          selectedKeys={selectedKeys}
          correctKeys={correctKeys}
          showSubmitButton={showSubmitButton}
          showResponse={showResponse}
          isOptionTouched={isOptionTouched}
          isOptionsDisabled={isOptionsDisabled}
          onChangeOption={handleChangeOption}
          handleSubmit={handleSubmit}
          handleRetry={handleRetry}
          showError={showError}
        />
      </Container>
    );
  }
  return null;
};

MRQItemView.propTypes = {
  mcq: MCQPropTypesShape,
  onSubmit: PropTypes.func,
  isPreview: PropTypes.bool,
  showAllMode: PropTypes.string,
  preferences: PropTypes.shape({
    displayQuestionMode: PropTypes.string,
    showExplanation: PropTypes.string,
    showRightAnswer: PropTypes.string
  }),
  index: PropTypes.number,
  showMixedItemIndex: PropTypes.number,
  componentData: PropTypes.shape({
    _id: PropTypes.string
  }),
  totalItems: PropTypes.string,
  moduleId: PropTypes.string,
  contentId: PropTypes.string,
  _id: PropTypes.string,

  attemptId: PropTypes.string,
  mixedComponentSubmit: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired
};

MRQItemView.defaultProps = {
  componentData: {},
  onSubmit: () => {},
  isPreview: false,
  moduleId: '',
  contentId: '',
  attemptId: '',
  mixedComponentSubmit: () => {}
};

export default MRQItemView;
