import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { get, pick } from 'lodash';
import { Radio, RadioGroup } from 'atoms/Radio';
import Switch from 'atoms/Switch';

import { DEFAULT_CONTENT_PREFERENCES } from 'utils/constants';
import ImageThumbnail from 'atoms/ImageThumbnail';
import Feedback from 'atoms/Feedback';
import { useFormatMessage } from 'hooks/useFormatMessage';
import { mediaSelector } from 'utils/selectors';
import { ErrorsPropTypesShape, SuccessMessagePropTypesShape, ComputedMatchPropTypesShape, HistoryPropTypesShape, CurrentContentPropTypesShape } from 'utils/core-proptypes';
import { carouselStoryContentAdditionalPrefs, carouselStoryPreferenceConstantToKeyMap } from 'utils/types';
import ActionRowContentPreference from '../../ActionRowContentPreference';
import CommonContentPreferences from '../../CommonContentPreferences';
import { AllowedMediaTypeSource, getMediaPayload } from 'utils/calculationUtilities';
import { addPreference, updatePreference } from '../../actions';
import './carousel-preference.scss';

const initialState = {
  preferences: {
    ...DEFAULT_CONTENT_PREFERENCES.CAROUSEL
  },
  contentPreferencePristine: true
};

const reducer = (state, action) => {
  const { key, value } = action;
  switch (action.type) {
    case 'inputChange':
    case 'dropDown':
    case 'inputRadio':
      return {
        ...state,
        preferences: { ...state.preferences, [key]: value },
        contentPreferencePristine: false
      };

    case 'setThumbnail': {
      const { thumbnail } = action;
      return {
        ...state,
        preferences: { ...state.preferences, thumbnail },
        contentPreferencePristine: false,
        thumbnailPristine: true
      };
    }
    case 'initializeUpdateState': {
      const { currentContent } = action;
      return {
        ...state,
        ...currentContent,
        preferences: {
          ...(action.currentContent.preferences || initialState.preferences)
        },
        contentPreferencePristine: true,
        thumbnailPristine: false
      };
    }
    default:
      throw new Error();
  }
};

const CarouselPreference = ({ currentContent, computedMatch, ...props }) => {
  const t = useFormatMessage();
  const [state, dispatcher] = useReducer(reducer, initialState);
  const { preferences } = state;
  const contentId = get(currentContent, '_id', null);
  const thumbnailValue = preferences.thumbnail && mediaSelector({ media: preferences.thumbnail });
  const { orgid } = computedMatch.params;

  useEffect(() => {
    if (currentContent) {
      dispatcher({
        type: 'initializeUpdateState',
        currentContent: currentContent
      });
    }
  }, [currentContent]);

  const handleFileModalSave = thumbnail => {
    dispatcher({
      type: 'setThumbnail',
      thumbnail
    });
  };

  const handleRevertToSaved = () => {
    dispatcher({
      type: 'initializeUpdateState',
      currentContent: currentContent
    });
  };

  const onSave = () => {
    const validKeys = [
      'contentName',
      'contentType',
      'askForConfirmation',
      'noOfTimeCanBeAccessed',
      'difficultyLevel',
      'estimatedTime',
      'description',
      'thumbnailType',
      'thumbnailUrl',
      'showContentOnDiploma'
    ];

    const payload = pick(
      {
        ...state,
        ...state.preferences,
        contentType: 'CAROUSEL',
        ...getMediaPayload(state.preferences.thumbnail, state.thumbnailPristine, 'thumbnail')
      },
      validKeys
    );

    if (currentContent && contentId) {
      props.updatePreference({ payload, id: contentId, organizationId: orgid });
    } else {
      props.addPreference({ payload, organizationId: orgid });
    }
  };

  return (
    <div className="content-preference">
      <CommonContentPreferences
        contentType="carousel"
        estimatedTime={preferences.estimatedTime}
        contentName={preferences.contentName}
        dispatcher={dispatcher}
        difficultyLevel={preferences.difficultyLevel}
        description={preferences.description}
      />
      <Row className="content-preference__body">
        <Col>
          <Row>
            <Col className="content-preference__row-left-border" style={{ borderTop: 'solid 1px #d2d3d2', paddingBottom: 0 }}>
              {t('common/thumbnail-text')}
              <p className="content-preference__help-text">{t('common-content-preference/help-text/thumbnail')}</p>
              <Col className="content-preference__body__thumbnail-container" style={{ marginTop: '20px' }}>
                <ImageThumbnail
                  variant="horizontal"
                  shouldUploadMindzeedPlaceholderImage={!computedMatch?.params?.id && !contentId}
                  defaultThumbnail={thumbnailValue}
                  allowedExtension="image/*,image/gif"
                  allowedFileType={[AllowedMediaTypeSource.FILE]}
                  handleSave={handleFileModalSave}
                />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col className="content-preference__row-left-border">
              <RadioGroup
                helpTextClassName="content-preference__help-text"
                helpText={t('common-content-preference/help-text/attempts-tries')}
                label={t('carousel-content/how-many-times-can-the-user-access-the-content')}
                name="common-content-attempts"
                onSelect={({ value }) =>
                  dispatcher({
                    type: 'inputRadio',
                    key: 'noOfTimeCanBeAccessed',
                    value
                  })
                }
              >
                <Radio value="ONE" checked={preferences.noOfTimeCanBeAccessed === 'ONE'} label={t('common/one')} />
                <Radio value="TWO" checked={preferences.noOfTimeCanBeAccessed === 'TWO'} label={t('common/two')} />
                <Radio value="UNLIMITED" checked={preferences.noOfTimeCanBeAccessed === 'UNLIMITED'} label={t('common/unlimited')} />
              </RadioGroup>
            </Col>
          </Row>
          <ActionRowContentPreference
            handleRevertToSaved={handleRevertToSaved}
            onSave={onSave}
            contentPreferencePristine={state.contentPreferencePristine}
            organizationId={orgid}
            currentContent={currentContent}
          />
        </Col>
        <Col className="content-preference__body__thumbnail-container">
          {Object.keys(carouselStoryContentAdditionalPrefs).map(pref => (
            <Row key={pref} style={{ borderTop: pref === 'APPEAR_ON_DIPLOMA' ? 'solid 1px #d2d3d2' : 'none' }} className="content-preference__row">
              <Col className="content-preference__row__switch-label">{t(carouselStoryContentAdditionalPrefs[pref])}</Col>
              <Col className="content-preference__row__switch">
                <Switch
                  id={pref}
                  onSelect={() => {
                    dispatcher({
                      type: 'inputRadio',
                      key: carouselStoryPreferenceConstantToKeyMap[pref],
                      value: preferences[carouselStoryPreferenceConstantToKeyMap[pref]] === 'false' ? 'true' : 'false'
                    });
                  }}
                  value={preferences[carouselStoryPreferenceConstantToKeyMap[pref]] === 'false' ? false : true}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
      <Feedback
        allowedTypesKey="CarouselPreferences"
        message={props.message.ADD_PREFERENCE_SUCCESS || props.message.UPDATE_PREFERENCE_SUCCESS || []}
        errorList={props.errors.ADD_PREFERENCE_FAILURE || props.errors.UPDATE_PREFERENCE_FAILURE || []}
      />
    </div>
  );
};

CarouselPreference.propTypes = {
  addPreference: PropTypes.func.isRequired,
  updatePreference: PropTypes.func.isRequired,
  errors: PropTypes.arrayOf(ErrorsPropTypesShape).isRequired,
  message: PropTypes.arrayOf(SuccessMessagePropTypesShape).isRequired,
  currentContent: CurrentContentPropTypesShape,
  history: HistoryPropTypesShape,
  computedMatch: ComputedMatchPropTypesShape
};

const mapStateToProps = ({ contentState }) => {
  const errors = get(contentState, 'errors', []);
  const message = get(contentState, 'message', []);

  return {
    error: contentState.error,
    errors,
    message
  };
};
const mapDispatchToProps = dispatch => {
  return {
    addPreference: bindActionCreators(addPreference, dispatch),
    updatePreference: bindActionCreators(updatePreference, dispatch)
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CarouselPreference));
